<template>
	<section class="section-wrapper" v-background="background">
		<base-container>
			<cms-text :value="`${prefix}.section_title`" :props="{variant: 'heading', tag: 'h2', color: 'white' }" class="section-title"/>
			<div class="squad-tiles">
				<client-only>
					<grid-template cols="3">
						<base-specialist v-for="(specialist, index) in value.specialists" :key="index" :specialist="specialist"/>
					</grid-template>
				</client-only>
			</div>
			<base-font class="desc" tag="p" color="white">{{ value.section_desc }}</base-font>
		</base-container>
	</section>
</template>
<script>
import GridTemplate from '~/website/front/templates/GridTemplate.vue'
import ClientOnly from 'vue-client-only'
import BaseSpecialist from '../../components/atoms/BaseSpecialist/BaseSpecialist.vue'
export default {
	components: { GridTemplate, ClientOnly, BaseSpecialist },
	props: {
		prefix: String,
		value: Object
	},
	computed: {
		background () {
			const path = this.value.section_bckgr.path

			return {
				src: path,
				breakpoints: {
					base: { width: 1200, height: 921 },
					xl: { width: 1200, height: 921 },
					xxl: { width: 1920, height: 609 }
				}
			}
		}
	}
}
</script>
<style lang="scss" scoped>
.section-wrapper {
	position: relative;
    padding: 4rem 0;
	background-repeat: no-repeat;
	background-size: cover;
    @include media-breakpoint-up(xl) {
        padding: 8rem 0;
    }

	&:before{
		position: absolute;
		width: 100%;
		top: 0;
		height: 5px;
		background: rgba(61, 158, 211, 0.83);
		content: '';
		display: none;
		@include media-breakpoint-up(xl) {
			display: block;
		}
	}
}

.section-title {
	margin-bottom: 3rem;
	@include media-breakpoint-up(xl) {
        margin-bottom: 6rem;
    }
	@include media-breakpoint-up(xxl) {
		text-align: center;
	}
}

::v-deep .grid-wrapper {
	gap: 3rem;
	grid-template-columns: auto;

	@include media-breakpoint-up(lg) {
		grid-template-columns: repeat(3, 1fr);
    }
}

.squad-tiles {
	margin: 2rem 0 3rem 0;

	@include media-breakpoint-up(xl) {
		margin: 4rem 0 6rem 0;
    }

	.container {
		padding: 0;
	}
}

::v-deep .desc {
	text-align: center;
}
</style>
